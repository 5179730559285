import React from 'react'
import { useAuth } from '@clerk/nextjs'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
  styled,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from '@mui/material'
import { Complaint, type ImportChart, type Patient } from '@/types'
import { useQueryGetVisitsByPatient, useMutateImportChart } from '@/hook'
import { format } from 'date-fns'
import { useToastStore } from '@/hook/useToast'

const StyledTextField = styled(TextField)(() => ({
  width: '506px',
  height: '40px !important',
  '& > div': {
    borderRadius: 0,
    height: '40px',
    backgroundColor: 'white',
    '& > input': {
      marginTop: '-2px'
    }
  }
}))

const StyledTableText = styled(Typography)({
  fontSize: '14px',
  color: '#454545'
})

const StyledBottomTableText = styled(Typography)({
  fontSize: '15px'
})

export const ImportChartDialog = ({
  patient,
  visitId,
  complaints,
  open,
  onClose,
  reloadSections
}: ImportChartDialogProps) => {
  const { getToken } = useAuth()
  const { addToast } = useToastStore()

  const [selectedVisit, setSelectedVisit] = React.useState<any>(null)
  const [isLoading, setIsLoading] = React.useState(false)

  const [checkboxState, setCheckboxState] = React.useState({
    historyOfPresentIllness: false,
    reviewOfSystems: true,
    exams: true,
    assessmentAndPlan: true
  })

  const keyDisplayNames: Record<string, string> = {
    historyOfPresentIllness: 'History of Present Illness',
    reviewOfSystems: 'Review of Systems',
    exams: 'Exams',
    assessmentAndPlan: 'Assessment and Plan'
  }

  const { data: visits } = useQueryGetVisitsByPatient(patient?.id!, getToken)

  const { mutateAsync: importChart } = useMutateImportChart(getToken)

  console.log('visit?.readOnlyVisitDate', visits)

  const options =
    visits
      ?.filter((visit) => visit.id !== visitId)
      .map((visit) => {
        let formattedDate = 'Unknown Date'
        if (visit?.readOnlyCreatedAt) {
          try {
            const createdDate = new Date(visit.readOnlyCreatedAt)
            if (!isNaN(createdDate.getTime())) {
              formattedDate = format(createdDate, 'MM/dd/yyyy')
            }
          } catch (error) {
            console.error('Error parsing date:', error)
          }
        }

        let complaintNames: string[] = []
        if (
          visit?.chiefComplaint?.complaintList &&
          visit.chiefComplaint.complaintList.length > 0
        ) {
          complaintNames = visit.chiefComplaint.complaintList.map(
            (complaintId: number) => {
              return (
                complaints?.find(
                  (complaint: Complaint) => complaint.id === complaintId
                )?.name ?? 'Unknown'
              )
            }
          )
        }

        const complaintsString =
          complaintNames.length > 0
            ? complaintNames.join(', ')
            : 'No complaints'

        return {
          label: `${formattedDate}: ${complaintsString}`,
          id: visit.id
        }
      }) || []

  const isAnyCheckboxSelected = Object.values(checkboxState).some(
    (value) => value
  )

  React.useEffect(() => {
    if (!open) {
      setSelectedVisit(null)
    }
  }, [open])

  const handleImport = async () => {
    if (!selectedVisit) return
    setIsLoading(true)

    const importChartData: ImportChart = {
      importExams: checkboxState.exams,
      appendExams: false,
      importRos: checkboxState.reviewOfSystems,
      appendRos: false,
      importAssessmentPlan: checkboxState.assessmentAndPlan,
      appendAssessmentPlan: false,
      importHpi: checkboxState.historyOfPresentIllness,
      appendHpi: false,
      importProcedures: false,
      appendProcedures: false,
      importCoding: false,
      appendCoding: false,
      importExternalOrders: false,
      appendExternalOrders: false
    }

    try {
      await importChart({
        visitId: visitId,
        sourceId: selectedVisit.id,
        importChartRequest: importChartData
      })
      reloadSections()
      setIsLoading(false)
      onClose()
      addToast({
        message: 'Information imported from past visit successfully',
        status: 'success'
      })
    } catch (error: any) {
      console.error('Import error:', error)
      setIsLoading(false)
      addToast({
        message: `Failed to import data: ${
          error.message || JSON.stringify(error)
        }`,
        status: 'error'
      })
    }
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    setCheckboxState((prevState) => ({
      ...prevState,
      [name]: checked
    }))
  }

  const handleVisitChange = (event: any, newValue: any) => {
    setSelectedVisit(newValue)
    if (newValue) {
      setCheckboxState({
        historyOfPresentIllness: true,
        reviewOfSystems: true,
        exams: true,
        assessmentAndPlan: true
      })
    }
  }

  const handleImportClick = () => {
    handleImport().catch((error) => {
      console.error('Unhandled error in handleImport:', error)
    })
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle sx={{ fontWeight: 'bold' }}>
          Import Chart Details
        </DialogTitle>
        <DialogContent>
          <StyledTableText variant="subtitle2">Select Visit</StyledTableText>
          <Autocomplete
            id="combo-box-demo"
            options={options}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <StyledTextField {...params} />}
            onChange={handleVisitChange}
          />
          {selectedVisit && (
            <div>
              <Typography
                variant="subtitle2"
                sx={{ marginTop: 1 }}
              >
                Select areas of the chart to import
              </Typography>
              <FormGroup>
                {Object.entries(checkboxState).map(([key, value]) => (
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        checked={value}
                        onChange={handleCheckboxChange}
                        name={key}
                        onClick={(event) => event.stopPropagation()}
                      />
                    }
                    label={keyDisplayNames[key]}
                    sx={{ marginBottom: -1 }}
                    onClick={(event) => event.preventDefault()}
                  />
                ))}
              </FormGroup>
              <StyledBottomTableText
                variant="subtitle2"
                color="error"
                sx={{ marginTop: 1, marginBottom: -1.5 }}
              >
                Note: this will overwrite any information already in that
                section
              </StyledBottomTableText>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={handleImportClick}
            color="primary"
            variant="contained"
            disabled={!selectedVisit || !isAnyCheckboxSelected}
          >
            Import
          </Button>
        </DialogActions>
      </Dialog>
      {isLoading && (
        <Dialog open={isLoading}>
          <DialogContent>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <CircularProgress />
              <Typography
                variant="body1"
                style={{ marginTop: 16, textAlign: 'center' }}
              >
                Loading{' '}
                {Object.entries(checkboxState)
                  .filter(([_, value]) => value)
                  .map(([key]) => keyDisplayNames[key])
                  .join(', ')}
                {selectedVisit && <> from past visit: {selectedVisit.label}</>}
              </Typography>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

interface ImportChartDialogProps {
  patient: Patient
  visitId: number
  complaints: any
  open: boolean
  onClose: () => void
  reloadSections: () => void
}
