///services/WorkersCompByPatientService.ts
import { type paths } from '../generated/schema'
import { Fetcher } from 'openapi-typescript-fetch'
import { type GetToken } from '@clerk/types'
import { type WorkersComp } from '@/types'

// Declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// Setup the fetch operation for WorkersComp claims by patientId
const setupGetWorkersCompClaims = fetcher
  .path('/api/VisitWorkersComp/Patient/{patientId}')
  .method('get')
  .create()

// Exported service function to get WorkersComp claims by patientId
export const getWorkersCompByPatientID = async (
  patientId: number,
  getToken: GetToken
): Promise<WorkersComp[]> => {
  // Configure the fetcher
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${(await getToken({ template: 'UrgentIQ' })) as string}`
      },
    },
  })

  // Fetch the workers' compensation claims using the patientId
  try {
    const response = await setupGetWorkersCompClaims({ patientId })
    return response.data as WorkersComp[]
  } catch (error: any) {
    if (error instanceof setupGetWorkersCompClaims.Error) {
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // Handle 400 errors
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // Handle 500 errors
      }
    }
    throw error // Rethrow if it's another kind of error
  }
}
