import React from 'react'
import { Popover, Box, Typography, TextField, styled } from '@mui/material'
import { ModalButton } from '../Config'
import { PillSearchBox, TileDatePicker } from '../Charting'
import { useAuth } from '@clerk/nextjs'
import {
  useClinicStore,
  useQueryGetAccountUsers,
  useQueryGetComplaints,
  useQueryGetProcedureTypes,
  useQueryGetRooms,
  useQueryGetVisitTypes,
} from '@/hook'
import { format } from 'date-fns'
import { isEmpty } from '@/utility'

const StyledTextField = styled(TextField)({
  width: '240px',
  height: '40px !important',
  '& > div': {
    borderRadius: 0,
    height: '40px',
    backgroundColor: 'white',
  },
})

export const FilterByPopup = ({
  isOpen = false,
  anchorEl,
  category,
  onClose,
  onDone,
}: FilterByPopupProps): JSX.Element => {
  const { getToken } = useAuth()
  const { clinicId } = useClinicStore()
  const { data: visitTypes } = useQueryGetVisitTypes(clinicId, getToken)
  const { data: rooms } = useQueryGetRooms(getToken)
  const { data: accountUsers } = useQueryGetAccountUsers(getToken)
  const { data: procedureTypes } = useQueryGetProcedureTypes(
    clinicId,
    getToken
  )
  const { data: complaints } = useQueryGetComplaints(getToken)

  const [textValue, setTextValue] = React.useState<string>('')
  const [selectValue, setSelectValue] = React.useState<SelectProps>()
  const [dateValue, setDateValue] = React.useState<Date | null>(null)

  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const regex = /^[0-9\b]+$/
    if (category === 'Total Time') {
      if (event.target.value === '' || regex.test(event.target.value)) {
        setTextValue(event.target.value)
      }
    } else {
      setTextValue(event.target.value)
    }
  }

  const onChangeSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: SelectProps
  ): void => {
    setSelectValue(value)
  }

  const onChangeDate = (event: any): void => {
    setDateValue(event)
  }

  const onFilterApply = (): void => {
    onDone(
      category,
      filterType === 'text'
        ? textValue
        : filterType === 'select'
        ? selectValue
        : format(dateValue!, 'MM/dd/yyyy')
    )
    onClose()
    if (filterType === 'text') {
      setTextValue('')
    } else if (filterType === 'select') {
      setSelectValue(undefined)
    } else {
      setDateValue(null)
    }
  }

  const filterType = React.useMemo((): string => {
    if (category === 'Patient Name' || category === 'Total Time') {
      return 'text'
    } else if (
      category === 'Insurance Status' ||
      category === 'Reason' ||
      category === 'Type of Visit' ||
      category === 'Staff' ||
      category === 'Room' ||
      category === 'Provider' ||
      category === 'Orders & Procedures'
    ) {
      return 'select'
    }
    return 'date'
  }, [category])

  const providers = React.useMemo(() => {
    return accountUsers?.filter(
      (accountUser) => !isEmpty(accountUser.npiNumber)
    )
  }, [accountUsers])

  const getFilterOptions = React.useMemo((): any[] | undefined => {
    switch (category) {
      case 'Insurance Status':
        return [
          {
            label: 'Insured',
            id: 1
          },
          {
            label: 'Self Pay',
            id: 2
          }
        ]
      case 'Type of Visit':
        return visitTypes?.map((type: any) => ({
          label: type.name,
          id: type.id
        }))
      case 'Reason':
        return complaints?.map((complaint: any) => ({
          label: complaint.name,
          id: complaint.id
        }))
      case 'Staff':
        return accountUsers?.map((provider: any) => ({
          label: `${provider?.firstName as string} ${
            provider?.lastName as string
          }`,
          id: provider.id
        }))
      case 'Room':
        return rooms?.map((room: any) => ({ label: room.name, id: room.id }))
      case 'Provider':
        return providers?.map((provider: any) => ({
          label: `${provider?.firstName as string} ${
            provider?.lastName as string
          }`,
          id: provider.id
        }))
      case 'Orders & Procedures':
        return procedureTypes?.map((order: any) => ({
          label: order.name,
          id: order.id
        }))
      default:
        break
    }
    return []
  }, [category])

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box
        sx={{
          padding: '20px',
          width: '280px',
        }}
      >
        <Typography color="#686868" fontSize="14px" sx={{ mb: 1 }}>
          {category}
          {category === 'Total Time' ? ' (min)' : ''}
        </Typography>
        {filterType === 'text' ? (
          <StyledTextField
            value={textValue}
            onChange={onChangeText}
            inputProps={{ 'data-testid': 'filterby-value-text' }}
          />
        ) : filterType === 'select' ? (
          <PillSearchBox
            values={[selectValue] as SelectProps[]}
            onChange={onChangeSelect}
            options={getFilterOptions!}
            dataTestId="filterby-value-select"
            singleColumn
            multiple={false}
          />
        ) : (
          <TileDatePicker
            dataTestId="filter-by-value-date"
            label=""
            value={dateValue}
            onChange={onChangeDate}
          />
        )}
        <Box sx={{ mt: 2 }}>
          <ModalButton variant="outlined" onClick={onFilterApply}>
            Done
          </ModalButton>
        </Box>
      </Box>
    </Popover>
  )
}
interface FilterByPopupProps {
  isOpen: boolean;
  anchorEl: HTMLButtonElement | null;
  onClose: any;
  category: string;
  onDone: any;
}

interface SelectProps {
  id: number;
  label: string;
}
