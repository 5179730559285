import React from 'react'
import {
  Badge,
  Chip,
  Dialog,
  Divider,
  IconButton,
  MenuItem,
  Modal,
  Select,
  type SelectChangeEvent,
  styled,
  type Theme,
  Typography,
  useTheme
} from '@mui/material'
import { useAuth, useUser } from '@clerk/nextjs'
import { UserBanner } from '@/components/UserBanner'
import { type AccountUser } from '@/types'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import MenuIcon from '@mui/icons-material/Menu'
import { useQueryGetDSToken } from '@/hook/useDoseSpotToken'
import {
  useQueryGetDoseSpotAlert,
  useClinicStore,
  useGlobalStore,
  useQueryGetClinics,
  useQueryGetAccountUsers
} from '@/hook'
import Image from 'next/image'
import { UnsavedDataPopup } from '../UnsavedDataPopup/UnsavedDataPopup'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Intercom from '@intercom/messenger-js-sdk'

const TitleBarContainer = styled('div')(({ theme }): any => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: '60px',
  width: '100%',
  alignItems: 'center',
  alignContent: 'center',
  backgroundColor: theme.palette.background.default,
  padding: '12px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
  position: 'fixed',
  zIndex: 1000,
  top: 0
}))
const StyledSelect = styled(Select)({
  borderRadius: '6px',
  height: '32px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  padding: '6px 8px 6px 12px',
  minWidth: '177px',
  maxWidth: '177px'
})
const TitleBarColumn = styled('div')(({ theme }): any => ({
  display: 'flex',
  gap: 8,
  paddingTop: '10px'
}))
const IconBox = styled('div')(({ theme }): any => ({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
  gap: 2,
  marginRight: '15px'
}))

interface CircleProps {
  isNavOpen: boolean
  theme: Theme
}

const Circle = styled('div')(({ isNavOpen, theme }: CircleProps): any => ({
  height: '40px',
  width: '40px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
  marginTop: '10px',
  backgroundColor: isNavOpen
    ? theme.palette.primary.main
    : theme.palette.background.paper
}))
const PageText = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '20px',
  lineHeight: '24px',
  fontWeight: 600,
  color: 'black',
  marginLeft: '17px'
})
const RightColumn = styled('div')({
  display: 'flex'
})
const NotificationBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
  width: '60px',
  height: '60px'
})

const AlertIframe = styled('iframe')({
  position: 'relative',
  width: '1440px',
  height: '810px',
  border: 'none',
  borderRadius: '12px'
})

interface TitleBarProps {
  pageTitle: string
  isNavOpen: boolean
  setIsNavOpen: React.Dispatch<React.SetStateAction<boolean>>
  isLocked?: boolean
}

export const TitleBar = ({
  pageTitle,
  isNavOpen,
  setIsNavOpen,
  isLocked = false
}: TitleBarProps): JSX.Element => {
  const NavIconBox = styled('div')({
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    marginLeft: '-12px',
    width: '80px',
    height: '60px',
    marginTop: '-12px',
    backgroundColor: 'white'
  })
  const { user } = useUser()
  const theme = useTheme()
  const { getToken } = useAuth()
  const { clinics, clinicId, setClinicId } = useClinicStore()
  const { refetch: getClinics } = useQueryGetClinics(getToken)
  const [alertCount, setAlertCount] = React.useState<number>(0)
  const [openAlerts, setOpenAlerts] = React.useState<boolean>(false)
  const [currentUser, setCurrentUser] = React.useState<AccountUser>()
  const [isIntercomOpen, setIsIntercomOpen] = React.useState(false)

  const { data: users } = useQueryGetAccountUsers(getToken)

  const { data: dsAlert, refetch: loadAlerts } = useQueryGetDoseSpotAlert(
    clinicId,
    getToken
  )
  const { data: dsToken, refetch: loadDSToken } = useQueryGetDSToken(
    clinicId,
    getToken
  )
  const handleCloseAlerts = (): void => setOpenAlerts(false)
  const handleOpenAlerts = (): void => setOpenAlerts(true)
  const {
    setIsEditing,
    setIsUnsavedDataPopupOpen,
    isUnsavedDataPopupOpen,
    setNextLocation,
    globalClinicId,
    setGlobalClinicId
  } = useGlobalStore()

  React.useEffect(() => {
    setAlertCount(
      ((dsAlert?.pendingPrescriptionsCount as number) ?? 0) +
        ((dsAlert?.pendingRxChangeCount as number) ?? 0) +
        ((dsAlert?.refillRequestsCount as number) ?? 0) +
        ((dsAlert?.transactionErrorsCount as number) ?? 0)
    )
  }, [dsAlert])

  React.useEffect(() => {
    if (users && user) {
      const matchedUser = users.find(
        (userItem: AccountUser) =>
          userItem.username?.toLowerCase() === user?.username?.toLowerCase()
      )
      setCurrentUser(matchedUser)
    }
  }, [users, user])

  const onClinicChange = (event: SelectChangeEvent<unknown>): void => {
    const newClinicId = event.target.value as number
    setGlobalClinicId(newClinicId)
    setClinicId(newClinicId)
  }

  React.useEffect(() => {
    if (!clinics || clinics.length === 0) {
      getClinics().catch((error) => {
        console.error('Failed to load clinics:', error)
      })
      return
    }
    if (clinicId > 0) {
      return
    }
    const firstClinicId = clinics[0]?.id ?? 0
    const clinicIds = clinics.map((clinic) => clinic.id)
    const isValidGlobalClinicId = clinicIds.includes(globalClinicId!)
    const defaultClinicId = isValidGlobalClinicId
      ? globalClinicId
      : firstClinicId

    if (globalClinicId !== defaultClinicId) {
      setGlobalClinicId(defaultClinicId!)
    }
    setClinicId(defaultClinicId!)
  }, [clinics])

  React.useEffect(() => {
    const interval = setInterval(() => {
      loadAlerts().catch((error) => {
        throw error
      })
    }, 600000)

    return () => {
      clearInterval(interval)
    }
  })

  React.useEffect(() => {
    if (
      user?.publicMetadata.account_user_id === undefined ||
      user?.publicMetadata.account_user_id === null
    ) {
      window.location.href = 'https://urgentiq.com/'
    }
  }, [user])

  let url = ''
  if (dsToken !== undefined) {
    if (
      dsToken?.doseSpotClinicId === undefined ||
      dsToken?.doseSpotClinicId === 0
    ) {
      loadDSToken().catch((error) => {
        throw error
      })
    }
    url = `${process.env.DOSESPOT_URL ?? ''}SingleSignOnClinicId=${
      dsToken?.doseSpotClinicId ?? 0
    }
      &SingleSignOnUserId=${dsToken?.doseSpotUserId ?? 0}
      &SingleSignOnPhraseLength=32&SingleSignOnCode=${
        dsToken?.clinicSingleSignOnEncoded ?? ''
      }
      &SingleSignOnUserIdVerify=${
        dsToken?.userSingleSignOnEncoded ?? ''
      }&RefillsErrors=1`
  }

  const handleLeaveAnyway = (leaveAnyway: boolean): void => {
    if (isUnsavedDataPopupOpen) {
      if (leaveAnyway) {
        setIsEditing(false)
      } else {
        setIsEditing(true)
        setNextLocation(null)
      }
    }
    setIsUnsavedDataPopupOpen(false)
  }

  Intercom({
    app_id: 'c1ye8nmi',
    user_id: currentUser?.id?.toString(),
    name: `${currentUser?.firstName} ${currentUser?.lastName}`,
    email: currentUser?.email ?? '',
    created_at: (user?.createdAt as Date)?.getTime(),
    hide_default_launcher: true
  })

  const openIntercom = () => {
    if (isIntercomOpen) {
      (window as any).Intercom('hide')
    } else {
      (window as any).Intercom('show')
    }
    setIsIntercomOpen(!isIntercomOpen)
  }

  React.useEffect(() => {
    (window as any).Intercom('onHide', () => {
      setIsIntercomOpen(false)
    })
  
    return () => {
      (window as any).Intercom('offHide')
    }
  }, [])

  return (
    <TitleBarContainer>
      <Modal
        open={openAlerts}
        onClose={handleCloseAlerts}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          width: '600px',
          height: '810px',
          marginLeft: '60px',
          marginTop: '60px'
        }}
      >
        <AlertIframe src={url} />
      </Modal>
      <TitleBarColumn>
        <NavIconBox>
          <Circle
            theme={theme}
            isNavOpen={isNavOpen}
          >
            <IconButton
              onClick={() => setIsNavOpen(!isNavOpen)}
              size="large"
            >
              <MenuIcon
                sx={{
                  color: isNavOpen
                    ? theme.palette.common.white
                    : theme.palette.primary.main
                }}
              />
            </IconButton>
          </Circle>
        </NavIconBox>
        <IconBox>
          <a
            href={`/?previousNavOpen=${isNavOpen.toString()}`}
            target="_self"
          >
            <Image
              src="/UrgentIQ_logo.svg"
              alt="Logo"
              height={40}
              width={120}
            />
          </a>
        </IconBox>
        {process.env.SENTRY_ENVIRONMENT === 'demo' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <Chip
              color="secondary"
              sx={{
                color: 'white',
                size: '13px',
                height: '18px',
                width: '120px'
              }}
              label="BETA TESTING"
            />
            <Typography sx={{ size: '13px', color: '#686868' }}>
              A playground for testing new features
            </Typography>
          </div>
        )}
        <Divider
          orientation="vertical"
          flexItem
          sx={{ color: '#DDDFE8', height: '26px', alignSelf: 'center' }}
        />
        <PageText color="primary">{pageTitle}</PageText>
      </TitleBarColumn>
      <RightColumn>
        {clinics?.length > 1 && (
          <StyledSelect
            value={clinicId}
            onChange={onClinicChange}
            sx={{ marginTop: '12px' }}
          >
            {clinics?.length > 1 &&
              clinics.map((clinic) => {
                if (clinic === undefined || clinic === null) return null
                return (
                  <MenuItem
                    key={clinic.id}
                    value={clinic.id}
                  >
                    {clinic.name}
                  </MenuItem>
                )
              })}
          </StyledSelect>
        )}
        <NotificationBox>
          <IconButton onClick={openIntercom}>
            <HelpOutlineIcon color="primary"/>
          </IconButton>
        </NotificationBox>
        <NotificationBox sx={{ marginLeft: '-20px' }}>
          <Badge
            badgeContent={alertCount}
            color="error"
            onClick={alertCount > 0 ? handleOpenAlerts : () => {}}
            data-testId={'notifications'}
            sx={{ ...(alertCount > 0 && { '&:hover': { cursor: 'pointer' } }) }}
          >
            <NotificationsNoneIcon color="disabled" />
          </Badge>
        </NotificationBox>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ color: '#DDDFE8', height: '26px', alignSelf: 'center' }}
        />
        <TitleBarColumn>
          <UserBanner currentUser={currentUser} />
        </TitleBarColumn>
        <Dialog
          open={isUnsavedDataPopupOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ borderRadius: '12px' }}
        >
          <UnsavedDataPopup leaveAnyway={handleLeaveAnyway} />
        </Dialog>
      </RightColumn>
    </TitleBarContainer>
  )
}
